import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Subscription from "@narative/gatsby-theme-novela/src/components/Subscription";

function Newsletter() {
  return (
    <Layout>
      <SEO />
      <Section>
      <br /><br /><br /><br />
        <Subscription />
      </Section>
    </Layout>
  );
}

export default Newsletter;
